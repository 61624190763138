import Loader from '@components/Loader';
import { FilterDropdown } from '@components/Molecules/FilterDropdown/FilterDropdown';
import { handleExport, hideTooltip, showTooltip } from '@utils/tableUtils';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { DeprecatedISimpleTableProps } from 'src/types/types';
import { PAGE_SIZE_OPTIONS } from '../../../core/constants';
import { Select, TransparentInput } from '../../../modules/dashboard/simCard';
import { DeprecatedSimpleTableBody } from './DeprecatedSimpleTableBody';
import { DeprecatedSimpleTableHeader } from './DeprecatedSimpleTableHeader';
import { DeprecatedSimpleTablePagination } from './DeprecatedSimpleTablePagination';

interface ISimpleTablePropsExtended extends DeprecatedISimpleTableProps {
  onRowClick?: (row: any) => void;
}

const DeprecatedSimpleTable = ({
  columns = [],
  data = [],
  title,
  titleFontWeight = '500',
  hasExport = true,
  hasPerPage = true,
  hasActions = false,
  hasSearch = false,
  actionItems,
  noDataText = 'No data',
  processing = false,
  filters = [],
  executingActionRow = null,
  updateFilter,
  resetFilter,
  customActionButton,
  autoResetPage = true,
  autoResetSortBy = true,
  initialPageSize = PAGE_SIZE_OPTIONS[0].value,
  hasPagination = true,
  cellProps = {},
  onRowClick,
}: ISimpleTablePropsExtended) => {
  const { t } = useTranslation();
  const memoizedColumns = useMemo(() => columns, [columns]);
  const memoizedData = useMemo(() => data, [data]);

  const tableOptions = useMemo(
    () => ({
      columns: memoizedColumns,
      data: memoizedData,
      autoResetPage,
      autoResetSortBy,
      initialState: {
        pageIndex: 0,
        pageSize: initialPageSize,
        hiddenColumns: columns.filter((column) => column.isVisible === false).map((column) => column.accessor),
      },
    }),
    [memoizedColumns, memoizedData, autoResetPage, autoResetSortBy, initialPageSize, columns],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
  } = useTable(tableOptions, useGlobalFilter, useSortBy, usePagination);

  const [tooltipVisibility, setTooltipVisibility] = useState({});

  const handleExportCallback = useCallback(() => handleExport(data), [data]);
  const memoizedSetGlobalFilter = useCallback((value: string) => setGlobalFilter(value), [setGlobalFilter]);
  const memoizedSetPageSize = useCallback((value: number) => setPageSize(value), [setPageSize]);

  const selectedPageSizeOption = useMemo(() => PAGE_SIZE_OPTIONS.find(({ value }) => value === pageSize), [pageSize]);

  const MemoizedFilterDropdown = useMemo(
    () =>
      filters.length > 0 ? (
        <FilterDropdown filters={filters} updateFilter={updateFilter} resetFilters={resetFilter} />
      ) : null,
    [filters, updateFilter, resetFilter],
  );

  if (processing) return <Loader />;

  return (
    <div className="tw-mb-0 tw-mt-0 tw-h-full tw-w-full">
      <div className="tw-rounded-lg tw-shadow">
        <div className="tw-flex tw-justify-between tw-rounded tw-rounded-b-none tw-bg-white tw-p-3 tw-text-bs-header">
          <div className="tw-flex tw-items-center tw-justify-between tw-align-middle">
            {hasSearch && (
              <div className="tw-flex-grow">
                <TransparentInput
                  placeholder="Search"
                  value={globalFilter || ''}
                  onChange={(e) => memoizedSetGlobalFilter(e.target.value)}
                  icon="search"
                />
              </div>
            )}
            {!!title && <h4 className={`tw-mb-0 tw-text-lg tw-font-${titleFontWeight}`}>{title}</h4>}
          </div>
          <div className="tw-flex tw-items-center tw-justify-between tw-gap-5 tw-py-2">
            {hasPerPage && (
              <Select
                options={PAGE_SIZE_OPTIONS}
                selected={selectedPageSizeOption}
                onClick={({ value }) => memoizedSetPageSize(value)}
              />
            )}
            {hasExport && (
              <button
                className="tw-inline-flex tw-items-center tw-rounded tw-border tw-border-solid tw-border-gray-300 tw-bg-white tw-px-3 tw-py-1 tw-text-sm tw-font-medium tw-text-gray-700 tw-shadow-none hover:tw-bg-gray-50"
                onClick={handleExportCallback}
              >
                <i className="fe fe-download tw-mr-2" />
                {t('export')}
              </button>
            )}
            {MemoizedFilterDropdown}
            {customActionButton}
          </div>
        </div>
        <div className="tw-flex-grow tw-overflow-visible">
          <table className="tw-mb-6 tw-w-full tw-rounded-lg tw-bg-white tw-text-xs" {...getTableProps()}>
            <DeprecatedSimpleTableHeader
              headerGroups={headerGroups}
              tooltipVisibility={tooltipVisibility}
              showTooltip={(columnId) => showTooltip(setTooltipVisibility, columnId)}
              hideTooltip={(columnId) => hideTooltip(setTooltipVisibility, columnId)}
            />
            {processing && (
              <tbody>
                <tr>
                  <td colSpan={100}>
                    <div className="d-flex justify-content-center">
                      <Loader />
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
            {!processing && data.length === 0 && (
              <tbody>
                <tr>
                  <td colSpan={100} className="tw-p-6">
                    <div className="d-flex justify-content-center">
                      <h3 className="py-2 m-0">{noDataText}</h3>
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
            {!processing && (
              <DeprecatedSimpleTableBody
                getTableBodyProps={getTableBodyProps}
                page={page}
                prepareRow={prepareRow}
                cellProps={cellProps}
                hasActions={hasActions}
                actionItems={actionItems}
                executingActionRow={executingActionRow}
                onRowClick={onRowClick}
              />
            )}
          </table>
        </div>
      </div>
      {hasPagination && (
        <DeprecatedSimpleTablePagination
          pageIndex={pageIndex}
          pageCount={pageCount}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      )}
    </div>
  );
};

export default DeprecatedSimpleTable;
