import ErrorMessage from '@components/ErrorMessage';
import { Loader } from '@components/Loader';
import { useCallback, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { Record } from 'src/types/types';
import DeprecatedSimpleTable from '../../../../components/Organisms/SimpleTable/DeprecatedSimpleTable';
import { useLazyGetInvoicePdfQuery } from '../billing-api-slice';

const PAYMENT_STATUS = {
  PAID: 'Paid',
  OUTSTANDING: 'Outstanding',
};

type InvoicesProps = {
  invoices: Record[];
  invoicesIsSuccess: boolean;
};

const Invoices = ({ invoices, invoicesIsSuccess }: InvoicesProps) => {
  const [errorMessage, setErrorMessage] = useState('');

  const columns = [
    {
      Header: 'INVOICE ID',
      accessor: 'invoice_id',
    },
    {
      Header: 'DATE',
      accessor: 'invoice_date',
    },
    {
      Header: 'AMOUNT',
      accessor: 'amount',
    },
    {
      Header: 'STATUS',
      accessor: 'payment_status',
      Cell: ({ value }) => (
        <Badge
          className="align-items-center justify-content-center fs-5"
          bg={value === PAYMENT_STATUS.PAID ? 'success' : 'secondary'}
        >
          {value}
        </Badge>
      ),
    },
  ];

  const [getPdf, _, __] = useLazyGetInvoicePdfQuery();

  const [rowLoading, setRowLoading] = useState<number | null>(null); //  This is to set the row in DeprecatedSimpleTable in loading state

  const handlePDFDownload = async (row: any) => {
    // This creates an invisible link to the pdf and clicks it to download
    // The reason for this workaround is that the file itself cant be serialized and stored in state
    // So instead we get the url and simulate a click to download to the client
    setRowLoading(row.index);
    const id = row?.values?.invoice_id || row.invoice_id;
    const invoicePdfUrl = await getPdf({ id });
    const link = document.createElement('a');
    link.id = 'download-pdf';
    link.href = invoicePdfUrl.data;
    link.download = `${id}.pdf`;
    link.click();

    setRowLoading(() => null);
    setTimeout(() => {
      // Remove the link from the body
      link.remove();
    }, 2000); // 1 second delay to make sure that the click is processed
  };

  const handleRowClick = useCallback(
    (row) => {
      handlePDFDownload(row);
    },
    [handlePDFDownload],
  );

  if (!invoicesIsSuccess) {
    return <Loader />;
  }

  return (
    <div>
      {!!invoices && !!columns && (
        <DeprecatedSimpleTable
          title="Invoices"
          data={invoices}
          columns={columns}
          hasPerPage={false}
          hasExport={false}
          hasActions
          executingActionRow={rowLoading}
          actionItems={[
            {
              title: 'Download PDF',
              handleClick: (row) => handlePDFDownload(row),
            },
          ]}
          onRowClick={handleRowClick}
        />
      )}
      <ErrorMessage message={errorMessage as string} onClose={() => setErrorMessage('')} />
    </div>
  );
};

export default Invoices;
