import { SIM_STATE } from '@core/constants';
import { coreApi } from '@core/rtk-api';
import {
  ActivityTimeline,
  DashboardParameters,
  DataUsageResponse,
  GetDataUsageRequest,
  GetNetworkRegistrationResponse,
  NetworkRegistrationRecord,
  RawDataUsageResponse,
  Simcard,
  SmsResponse,
  SmsUsageResponse,
  TagsResponse,
  UsageGraphResponse,
} from 'src/types/types';
import { simcardsSlice } from './simcards-slice';

export const simcardsApi = coreApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getSim: builder.query<Simcard, { iccid: string; silently?: boolean }>({
        query: ({ iccid, silently = false }) => ({ url: `/simcards/${iccid}${silently ? '?silently=true' : ''}` }),
        providesTags: (_, __, arg) => [
          { type: 'Simcard', id: arg.iccid },
          { type: 'Simcard', id: 'LIST' },
        ],
        transformResponse(response: Simcard, __, arg) {
          return { ...response, processing: !arg.silently };
        },
      }),
      getDashboardParameters: builder.query<DashboardParameters, void>({
        query: () => ({
          url: '/dashboard_parameters',
        }),
        transformResponse: (response: any) => {
          return {
            filters: response?.filters,
            trafficPolicies: response?.traffic_policies,
            radio: response?.radio,
            regions: response?.regions,
            months: response?.months,
            currencies: response?.currencies,
            publicIp: response?.public_ip,
          };
        },
      }),
      getTags: builder.query<TagsResponse, void>({
        query: () => ({ url: '/tags' }),
        providesTags: ['Tags'],
      }),
      updateSimStatus: builder.mutation<
        { iccids: string[]; sim_state: SIM_STATE },
        { iccids: string[]; sim_state: SIM_STATE }
      >({
        query: ({ iccids, sim_state }) => {
          return {
            url: `/simcards/status`,
            method: 'PATCH',
            body: { iccids, sim_state },
          };
        },
        transformResponse(_, __, arg) {
          let sim_state;
          switch (arg.sim_state) {
            case SIM_STATE.ENABLED:
              sim_state = SIM_STATE.ENABLING;
              break;
            case SIM_STATE.DISABLED:
              sim_state = SIM_STATE.DISABLING;
              break;
          }
          return { iccids: arg.iccids, sim_state };
        },
        invalidatesTags: (_, __, { iccids }): { type: 'Simcard'; id?: string }[] => {
          return [
            ...iccids.map((iccid) => ({
              // invalidates cache for single sim detail view
              type: 'Simcard' as const,
              id: iccid,
            })),
          ];
        },
      }),
      getNetworkRegistration: builder.query<GetNetworkRegistrationResponse, { iccid: string }>({
        query: ({ iccid }) => ({
          url: `/simcards/${iccid}/lu`,
        }),
        transformResponse: (response: {
          record?: NetworkRegistrationRecord[];
          records?: NetworkRegistrationRecord[];
        }) => {
          return { networkRegistration: response?.records || response?.record };
        },
      }),
      resetNetwork: builder.mutation<any, { iccid: string }>({
        query: ({ iccid }) => ({ url: `/simcards/${iccid}/reset`, method: 'POST' }),
      }),
      getSimsExport: builder.query<any, any>({
        query: (params: { search; state; coverage; tags; sort; dir }) => {
          return { url: `/simcards/csv?` + new URLSearchParams(params).toString() };
        },
      }),
      changeSimName: builder.mutation<any, { deviceName: string; iccid: string }>({
        query: ({ deviceName, iccid }) => ({
          url: `/simcards/${iccid}/name`,
          method: 'PATCH',
          body: { device_name: deviceName },
        }),
        transformResponse: (_, __, arg) => ({ device_name: arg.deviceName }),
        async onQueryStarted({ deviceName, iccid }, { dispatch, queryFulfilled }) {
          dispatch(
            simcardsSlice.actions.updateSimcardName({
              iccid,
              device_name: deviceName,
            }),
          );

          try {
            await queryFulfilled;
          } catch (error) {
            dispatch(
              simcardsSlice.actions.updateSimcardName({
                iccid,
                device_name: '',
              }),
            );
          }
        },
      }),
      getPlan: builder.query<any, { plan_id: string }>({
        query: ({ plan_id }) => ({ url: `/rateplans/${plan_id}` }),
        transformResponse: (data: any) => ({ simPlan: data }),
      }),
      getIpPricing: builder.query<any, void>({
        query: () => ({ url: `/pricing` }),
        transformResponse: (data: any) => ({ ipPricing: data }),
      }),
      getSms: builder.query<SmsResponse, { iccid: string }>({
        query: ({ iccid }) => ({ url: `simcards/${iccid}/sms` }),
        transformResponse: (data: any) => ({ sms: data.messages }),
      }),
      getSmsUsage: builder.query<SmsUsageResponse, { iccid: string }>({
        query: ({ iccid }) => ({ url: `simcards/${iccid}/usage/sms` }),
        transformResponse: (data: any) => ({ smsUsage: data.cdrs }),
      }),
      createTag: builder.mutation<any, { tag: string; color: string }>({
        query: ({ tag, color }) => ({
          url: `/tags`,
          method: 'POST',
          body: { tag, color },
        }),
        invalidatesTags: ['Tags'],
      }),
      deleteTag: builder.mutation<any, { tag: string }>({
        query: ({ tag }) => ({
          url: `/tags`,
          method: 'DELETE',
          body: { tag },
        }),
        invalidatesTags: ['Tags'],
      }),
      addTagToSims: builder.mutation<any, { tags: string[]; iccids: string[] }>({
        query: ({ tags, iccids }) => ({
          url: '/simcards/tags',
          method: 'PATCH',
          body: { tags, iccids },
        }),
        async onQueryStarted({ tags, iccids }, { dispatch, queryFulfilled }) {
          // Optimistically update simcards in simcardsSlice
          dispatch(
            simcardsSlice.actions.batchUpdateSimcardTags({
              iccids,
              tagsToAdd: tags,
              tagsToRemove: [],
            }),
          );

          try {
            await queryFulfilled;
          } catch {
            // Handle rollback if necessary
            dispatch(
              simcardsSlice.actions.batchUpdateSimcardTags({
                iccids,
                tagsToAdd: [],
                tagsToRemove: tags,
              }),
            );
          }
        },
        invalidatesTags: (_, __, { iccids }): { type: 'Simcard'; id?: string }[] => {
          return iccids.map((iccid) => ({
            type: 'Simcard' as const,
            id: iccid,
          }));
        },
      }),

      removeTagToSims: builder.mutation<any, { tags: string[]; iccids: string[] }>({
        query: ({ tags, iccids }) => ({
          url: '/simcards/tags',
          method: 'DELETE',
          body: { tags, iccids },
        }),
        async onQueryStarted({ tags, iccids }, { dispatch, queryFulfilled }) {
          // Optimistically update simcards in simcardsSlice
          dispatch(
            simcardsSlice.actions.batchUpdateSimcardTags({
              iccids,
              tagsToAdd: [],
              tagsToRemove: tags,
            }),
          );

          try {
            await queryFulfilled;
          } catch {
            // Handle rollback if necessary
            dispatch(
              simcardsSlice.actions.batchUpdateSimcardTags({
                iccids,
                tagsToAdd: tags,
                tagsToRemove: [],
              }),
            );
          }
        },
        invalidatesTags: (_, __, { iccids }): { type: 'Simcard'; id?: string }[] => {
          return iccids.map((iccid) => ({
            type: 'Simcard' as const,
            id: iccid,
          }));
        },
      }),
      sendSms: builder.mutation<any, { iccids: string[]; message: string }>({
        query: ({ iccids, message }) => ({
          url: `/simcards/sms`,
          method: 'POST',
          body: { iccids, message },
        }),
      }),
      getUsageGraph: builder.query<UsageGraphResponse, { iccid: string; type: string }>({
        query: ({ iccid, type }) => ({ url: `/simcards/${iccid}/usage/${type}` }),
        transformResponse: (data: any) => ({ usageGraph: data }),
      }),
      getDataUsage: builder.query<DataUsageResponse, GetDataUsageRequest>({
        query: ({ iccid }) => ({ url: `/simcards/${iccid}/usage/table` }),
        transformResponse: (data: RawDataUsageResponse) => ({ dataUsage: data.cdrs }),
      }),
      getActivityTimeline: builder.query<ActivityTimeline, { iccid: string }>({
        query: ({ iccid }) => ({ url: `/simcards/${iccid}/audit` }),
      }),
      updateImeiLock: builder.mutation<any, { iccids: string[]; imei_lock: 'on' | 'off' }>({
        query: ({ iccids, imei_lock }) => ({
          url: `/simcards/imeilock`,
          method: 'PATCH',
          body: { iccids, imei_lock },
        }),
        invalidatesTags: (_, __, { iccids }): { type: 'Simcard'; id?: string }[] => {
          return [
            ...iccids.map((iccid) => ({
              type: 'Simcard' as const,
              id: iccid,
            })),
          ];
        },
      }),
      setTrafficPolicy: builder.mutation<any, { iccids: string[]; policy: string }>({
        query: ({ iccids, policy }) => ({
          url: `/simcards/policy`,
          method: 'PATCH',
          body: { iccids, policy },
        }),
      }),
      changePlan: builder.mutation<any, { iccids: string[]; plan_id: string }>({
        query: ({ iccids, plan_id }) => ({
          url: `/simcards/rateplan`,
          method: 'PATCH',
          body: { iccids, plan_id },
        }),
        invalidatesTags: (_, __, { iccids }): { type: 'Simcard'; id?: string }[] => {
          return [
            ...iccids.map((iccid) => ({
              type: 'Simcard' as const,
              id: iccid,
            })),
          ];
        },
      }),
      deployPublicIp: builder.mutation<any, { iccid: string; data: any }>({
        query: ({ iccid, data }) => ({
          url: `/simcards/${iccid}/publicip`,
          method: 'POST',
          body: data,
        }),
      }),
      deletePublicIp: builder.mutation<any, { iccid: string }>({
        query: ({ iccid }) => ({
          url: `/simcards/${iccid}/publicip`,
          method: 'DELETE',
        }),
      }),
    };
  },
});

export const {
  useUpdateSimStatusMutation,
  useGetTagsQuery,
  useLazyGetTagsQuery,
  useLazyGetSimQuery,
  useGetSimQuery,
  useGetDashboardParametersQuery,
  useLazyGetDashboardParametersQuery,
  useLazyGetNetworkRegistrationQuery,
  useResetNetworkMutation,
  useLazyGetSimsExportQuery,
  useChangeSimNameMutation,
  useLazyGetPlanQuery,
  useLazyGetIpPricingQuery,
  useGetSmsQuery,
  useLazyGetSmsQuery,
  useGetSmsUsageQuery,
  useCreateTagMutation,
  useDeleteTagMutation,
  useAddTagToSimsMutation,
  useRemoveTagToSimsMutation,
  useSendSmsMutation,
  useGetUsageGraphQuery,
  useLazyGetUsageGraphQuery,
  useGetDataUsageQuery,
  useLazyGetDataUsageQuery,
  useLazyGetActivityTimelineQuery,
  useUpdateImeiLockMutation,
  useSetTrafficPolicyMutation,
  useChangePlanMutation,
  useDeployPublicIpMutation,
  useDeletePublicIpMutation,
} = simcardsApi;
